$markdown-font-sizes: (
  h1: 42px,
  h2: 34px,
  h3: 28px,
  h4: 22px,
  h5: 18px,
  h6: 18px,
);

body {
  .fr-box {
    .CodeMirror-gutters,
    .CodeMirror,
    &.fr-basic .fr-wrapper,
    .fr-second-toolbar {
      border-color: var(--mdc-filled-text-field-container-color);
      background-color: var(--md-sys-color-surface-container-lowest);
    }

    .fr-second-toolbar {
      border-radius: 0;

      .fr-counter {
        color: var(--md-sys-color-inverse-on-surface);
      }
    }
    .fr-toolbar.fr-top {
      border-top-left-radius: var(--mdc-filled-text-field-container-shape, var(--mat-app-corner-extra-small-top));
      border-top-right-radius: var(--mdc-filled-text-field-container-shape, var(--mat-app-corner-extra-small-top));
    }

    .CodeMirror,
    &.fr-basic .fr-element {
      color: var(--mdc-filled-text-field-input-text-color);
      background-color: var(--mdc-filled-text-field-container-color);
      font: inherit;
      letter-spacing: inherit;
      text-decoration: inherit;
      text-transform: inherit;
      -webkit-font-smoothing: antialiased;
    }

    .fr-element {
      // border-bottom: 1px solid;
      position: relative;
      line-height: 1.4 !important;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        border-bottom: 1px solid var(--mdc-filled-text-field-active-indicator-color);
      }

      &:focus::after {
        border-bottom-width: 2px;
        border-bottom-color: var(--mdc-filled-text-field-focus-active-indicator-color);
      }
    }

    .fr-wrapper.show-placeholder .fr-placeholder {
      z-index: 100;
      font-family: inherit;
      color: var(--md-sys-color-on-surface);
      opacity: 0.7;
      pointer-events: none;
      font-weight: 300;
    }

    .fr-second-toolbar {
      min-height: 0px;
      border-bottom: 0;
    }

    .fr-second-toolbar,
    .fr-toolbar {
      background-color: var(--md-sys-color-surface-container-high);
      border-color: var(--md-sys-color-surface-container-high);

      .fr-newline {
        background-color: var(--md-sys-color-surface-container-high);
      }

      .fr-command:hover:not(.fr-table-cell) {
        background-color: var(--md-sys-color-outline-variant);
      }
      // color: var(--md-sys-color-outline-variant);
      .fr-command.fr-btn svg path {
        fill: currentColor;
      }
      .fr-command.fr-btn {
        color: var(--md-sys-color-on-surface);
        transition: none;
      }

      .fr-command.fr-btn.fr-dropdown:after {
        border-top-color: currentColor;
      }

      .fr-command.fr-btn.fr-dropdown.fr-active:after {
        border-bottom-color: currentColor;
      }

      .fr-command.fr-btn.fr-open,
      .fr-command.fr-btn.fr-active,
      .fr-command.fr-btn.fr-btn-active-popup,
      .fr-command.fr-btn.fr-dropdown.fr-active {
        background-color: var(--md-sys-color-outline-variant);
      }

      .fr-command.fr-btn.fr-disabled {
        color: inherit;
        pointer-events: none;
      }

      .fr-command.fr-btn.fr-active {
        color: var(--md-sys-color-on-surface);
      }

      .fr-more-toolbar {
        background-color: var(--md-sys-color-outline-variant);
      }
    }

    .fr-command.fr-btn + .fr-dropdown-menu {
      background: var(--md-sys-color-surface-container-low);
      color: var(--md-sys-color-on-surface);

      .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a.fr-active {
        background-color: var(--md-sys-color-outline-variant);
      }
    }
  }

  .fr-popup {
    background-color: var(--md-sys-color-surface-container-lowest);
    color: var(--md-sys-color-on-surface);

    .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab,
    .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active,
    .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab,
    .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active,
    .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active {
      background-color: var(--md-sys-color-surface-container-lowest);
    }
    .fr-buttons.fr-tabs {
      background-color: var(--md-sys-color-surface-container-low);
    }

    .fr-image-upload-layer:hover {
      background-color: var(--md-sys-color-surface-container);
    }
  }

  .fr-toolbar .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
  .fr-toolbar .fr-more-toolbar .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
  .fr-toolbar .fr-buttons:not(.fr-tabs) .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
  .fr-popup .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
  .fr-popup .fr-more-toolbar .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
  .fr-popup .fr-buttons:not(.fr-tabs) .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
  .fr-modal .fr-btn-grp .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
  .fr-modal .fr-more-toolbar .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path,
  .fr-modal .fr-buttons:not(.fr-tabs) .fr-command.fr-btn.fr-active:not(.fr-dropdown) svg path {
    fill: var(--md-sys-color-tertiary);
  }

  .fr-popup .fr-input-line input[type='text'],
  .fr-popup .fr-input-line input[type='number'],
  .fr-popup .fr-input-line textarea {
    background-color: var(--md-sys-color-surface-container-lowest);
    color: var(--md-sys-color-on-surface);
  }

  .fr-popup .fr-input-line input + label,
  .fr-popup .fr-input-line textarea + label {
    background-color: var(--md-sys-color-surface-container-lowest);
    color: var(--md-sys-color-on-surface);
  }

  .fr-popup .fr-input-line input[type='text']:focus,
  .fr-popup .fr-input-line input[type='number']:focus,
  .fr-popup .fr-input-line textarea:focus {
    border-color: var(--md-sys-color-tertiary);
  }

  .fr-popup .fr-action-buttons button.fr-command:hover,
  .fr-popup .fr-action-buttons button.fr-command:focus,
  .fr-popup .fr-action-buttons button.fr-command,
  .fr-submit {
    color: var(--md-sys-color-tertiary);
  }

  .fr-popup .fr-checkbox input:checked + span {
    background-color: var(--md-sys-color-tertiary);
    border-color: var(--md-sys-color-tertiary);
    color: var(--md-sys-color-surface-container-lowest);

    svg path {
      fill: currentColor !important;
    }
  }

  .fr-qi-helper a.fr-btn.fr-floating-btn,
  .fr-box a.fr-floating-btn {
    background-color: var(--md-sys-color-inverse-surface);
    color: var(--md-sys-color-inverse-on-surface);

    svg {
      fill: currentColor;
    }
  }

  .fr-element {
    em,
    i {
      font-style: italic;
    }

    strong {
      font-weight: 800;
    }

    @each $name, $size in $markdown-font-sizes {
      * + #{$name},
      * + .#{$name} {
        margin-top: map-get($spacers, 6);
      }

      #{$name},
      .#{$name} {
        font-size: rem($size);
        margin-bottom: 1ex;
        font-weight: 500;
      }
    }

    p + ol,
    p + ul {
      margin-top: -1rem;
    }

    ul,
    ol {
      list-style: outside;
      padding-inline-start: 2ex;
      display: inline-block;
      margin-bottom: 1ex;
      width: 100%;

      li {
        display: list-item;
        list-style-type: initial;
        font-weight: 400;
      }

      ul {
        li {
          list-style-type: circle;

          ul li {
            list-style-type: square;

            ul li {
              list-style: disc;
            }
          }
        }
      }

      ul,
      ol {
        margin-bottom: 1ex;
      }
    }

    ol li {
      list-style-type: decimal;
    }

    hr {
      margin: 1.5em 0;
    }

    p {
      margin-bottom: 3ex;
    }

    img {
      max-width: 100%;
    }
    p,
    table,
    ul,
    ol,
    dl,
    blockquote {
      //max-width: 800px;
    }

    pre {
      white-space: pre-line;
      font-family: monospace;

      padding: 1em;
      border-radius: 7px;
      margin-bottom: 1.5em;
    }

    .button + .button {
      margin-inline-start: 0.5em;
    }

    // a:not(.button) {
    //   color: var(--bs-primary);

    //   @media (hover: hover) {
    //     &:hover {
    //       background: var(--bs-primary);
    //       color: var(--bs-light);
    //     }
    //   }
    // }
  }
}

// Custom toolbar button icon styles
.fr-toolbar .fr-command.fr-btn .mat-icon {
  font-size: 24px;
  width: 40px;
  height: 40px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

[data-custom-content] {
  width: 100%;
  display: block;
  min-height: 100px;
  background-color: var(--md-sys-color-secondary-container);
  color: var(--md-sys-color-on-secondary-container);
  border-radius: 10px;

  border: dashed 2px var(--md-sys-color-secondary);
  display: inline-flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
  margin-top: 1em;
  padding: 1.4em 0;
  transition: background-color 0.2s ease;
  cursor: pointer;
  position: relative;

  &:before {
    content: attr(data-custom-content);
    // content: calc(attr(data-type) + attr(data-custom-content));
  }

  &::after {
    content: 'Open';
    @include abs(5px, 5px, null, null);
    border-radius: 10px;
    color: var(--md-sys-color-secondary-container);
    background-color: var(--md-sys-color-on-secondary-container);
    display: inline-flex;
    padding: 0.2em 0.5em;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &:hover {
    background-color: color-mix(in srgb, var(--md-sys-color-secondary-container), var(--md-sys-color-on-secondary-container) 20%);
    &::after {
      opacity: 1;
    }
  }
}
